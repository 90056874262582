import { defineStore } from "pinia";
import {
  getInvoicingMeetings,
  updateMeetingInvoicingStatus,
} from "~/helpers/api/meeting";
import type { InvoicingMeeting } from "~/model/meeting/invoicingMeeting";
import { MeetingInvoicingStatus, type Meeting } from "~/model/meeting";
import {
  addDays,
  format,
  getYear,
  isAfter,
  isBefore,
  isValid,
  parseISO,
  subMonths,
} from "date-fns";

export const getInitialState = (): InvoiceStoreState => ({
  isLoading: false,
  page: 1,
  pageSize: 10,
  filter: {
    status: null,
    windowEnd: null,
    windowStart: null,
  },
  invoicingMeetings: [],
  filterForm: {
    status: null,
    windowEnd: null,
    windowStart: null,
  },
});

export const useInvoiceStore = defineStore("invoice", {
  state: getInitialState,
  getters: {
    isInvoiceLoading: (state) => state.isLoading,
    getWindowIntervals: (state) => {
      const today = new Date();

      const minWindowStartDate = format(subMonths(today, 3), "yyyy-MM-dd");
      const maxWindowEndDate = format(addDays(today, 1), "yyyy-MM-dd");

      const maxWindowStartDate = format(
        new Date(state.filterForm.windowEnd ?? maxWindowEndDate),
        "yyyy-MM-dd"
      );

      const minWindowEndDate = format(
        new Date(state.filterForm.windowStart ?? minWindowStartDate),
        "yyyy-MM-dd"
      );

      return {
        windowStart: {
          min: minWindowStartDate,
          max: maxWindowStartDate,
        },
        windowEnd: {
          min: minWindowEndDate,
          max: maxWindowEndDate,
        },
      };
    },
    getIsWindowStartValid: (state) => {
      var errors = [];
      const selectedStartDate = state.filterForm.windowStart
        ? parseISO(state.filterForm.windowStart)
        : null;
      const selectedEndDate = state.filterForm.windowEnd
        ? parseISO(state.filterForm.windowEnd)
        : null;
      const today = new Date();
      const minDate = subMonths(today, 3);
      const maxDate = addDays(today, 1);

      if (state.filterForm.windowStart == null) {
        errors = [null];
      } else if (getYear(selectedStartDate) < 1900) {
        errors = ["Geçerli bir tarih seçiniz."];
      } else if (!isValid(selectedStartDate)) {
        errors = ["Geçerli bir tarih seçiniz."];
      } else if (isAfter(selectedStartDate, maxDate)) {
        errors = ["Bugünden ileri bir tarih seçemezsiniz."];
      } else if (isBefore(selectedStartDate, minDate)) {
        errors = [
          "Başlangıç tarihi en erken " + formatDate(minDate) + " olabilir.",
        ];
      } else if (
        selectedEndDate &&
        isAfter(selectedStartDate, selectedEndDate)
      ) {
        errors = ["Başlangıç tarihi bitiş tarihinden önce olmalıdır."];
      } else {
        errors = [null];
      }

      return errors?.filter((x) => x && x.length > 0);
    },
    getIsWindowEndValid: (state) => {
      var errors = [];
      const selectedStartDate = state.filterForm.windowStart
        ? parseISO(state.filterForm.windowStart)
        : null;
      const selectedEndDate = state.filterForm.windowEnd
        ? parseISO(state.filterForm.windowEnd)
        : null;
      const today = new Date();
      const minDate = subMonths(today, 3);
      const maxDate = addDays(today, 1);

      if (state.filterForm.windowEnd == null) {
        errors = [null];
      } else if (getYear(selectedEndDate) < 1900) {
        errors = ["Geçerli bir tarih seçiniz."];
      } else if (!isValid(selectedEndDate)) {
        errors = ["Geçerli bir tarih seçiniz."];
      } else if (isAfter(selectedEndDate, maxDate)) {
        errors = ["Bugünden ileri bir tarih seçemezsiniz."];
      } else if (isBefore(selectedEndDate, minDate)) {
        errors = [
          "Bitiş tarihi en erken " + formatDate(minDate) + " olabilir.",
        ];
      } else if (
        selectedStartDate &&
        isAfter(selectedStartDate, selectedEndDate)
      ) {
        errors = ["Bitiş tarihi başlangıç tarihinden sonra olmalıdır."];
      } else {
        errors = [null];
      }

      return errors?.filter((x) => x && x.length > 0);
    },
    getFilterFormChanged: (state) => {
      return getChangedPropertyCount(state.filter, state.filterForm) > 0;
    },
    getFilterFormIsDifferentFromInitialFilter: (state) => {
      return getChangedPropertyCount(state.filter, state.filterForm) > 0;
    },
  },
  actions: {
    async loadInvoices() {
      this.isLoading = true;
      try {
        const meetings = (await getInvoicingMeetings(this.filter)) as Meeting[];
        this.invoicingMeetings = meetings.map(mapMeetingToInvoicingMeeting);
      } catch (e) {}
      this.isLoading = false;
    },
    async changeInvoiceStatus(
      meetingId: string,
      oldStatus: MeetingInvoicingStatus
    ) {
      const newValue =
        oldStatus === MeetingInvoicingStatus.Invoiced ? false : true;

      try {
        await updateMeetingInvoicingStatus(meetingId, newValue);
        const newStatus = newValue
          ? MeetingInvoicingStatus.Invoiced
          : MeetingInvoicingStatus.NotInvoiced;

        const meeting = this.invoicingMeetings.find((m) => m.id === meetingId);
        if (meeting) {
          meeting.invoicingStatus = newStatus;
        }
      } catch (e) {
        console.error(e);
      }
    },
    setPage(page: number) {
      this.page = page;
    },
    updateFilter() {
      this.filter = this.filterForm;
      this.page = 1;
    },
    clearFilter() {
      this.filter = { ...getInitialState().filter };
    },
    clearFilterProperty(property: keyof InvoiceFilter) {
      if (property === "status") {
        this.filter = { ...this.filter, status: null };
      } else if (property === "windowStart") {
        this.filter = { ...this.filter, windowStart: null };
      } else if (property === "windowEnd") {
        this.filter = { ...this.filter, windowEnd: null };
      }
    },
    initializeFilterForm() {
      this.filterForm = { ...this.filter };
    },
    resetFilterForm() {
      this.filterForm = { ...getInitialState().filter };
    },
  },
});

function mapMeetingToInvoicingMeeting(meeting: Meeting): InvoicingMeeting {
  const client = meeting.participants?.find((p) => p.userType === "Client");

  return {
    id: meeting.id,
    clientName: client
      ? `${client.firstname} ${client.lastname}`
      : "Silinmiş Kullanıcı",
    meetingDate: formatDate(meeting.startDate),
    meetingTime: formatTime(meeting.startDate),
    invoicingAmount: meeting.invoicingAmount,
    invoicingStatus: meeting.invoicingStatus,
    email: client?.email ?? "-",
    clientId: client?.id ?? `deleted-client-${meeting.id}`,
  };
}

export const invoiceOptions = [
  { label: "Tümü", value: null },
  { label: "Fatura kesilenler", value: MeetingInvoicingStatus.Invoiced },
  { label: "Fatura kesilecekler", value: MeetingInvoicingStatus.NotInvoiced },
];

export type InvoiceStoreState = {
  isLoading: boolean;
  filter: InvoiceFilter;
  invoicingMeetings: InvoicingMeeting[];
  page: number;
  pageSize: number;
  filterForm: InvoiceFilter;
};

export type InvoiceFilter = {
  status: MeetingInvoicingStatus;
  windowStart?: string;
  windowEnd?: string;
};
