export function getChangedPropertyNames<T>(obj1: T, obj2: T): (keyof T)[] {
  const changedProperties: (keyof T)[] = [];

  for (const key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      if (obj1[key] !== obj2[key]) {
        changedProperties.push(key as keyof T);
      }
    }
  }

  return changedProperties;
}

export function getChangedPropertyCount<T>(obj1: T, obj2: T): number {
  return getChangedPropertyNames(obj1, obj2).length;
}
